import React, { useEffect, useContext } from 'react';
import { Layout } from '../../components/Layout';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Img from 'gatsby-image';
import dayjs from 'dayjs';
import { styleVariables, siteColors } from '../../styles/Variables';
import { HTag } from '../../components/HTag';
import { SiteContext } from '../../state';

const StyledUpdates = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const StyledArticle = styled.article`
  max-width: 767px;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: ${styleVariables.borderRadius};
  h2 {
    margin-bottom: 0;
    text-transform: uppercase;
    a {
      color: ${siteColors.primary};
      text-decoration: none;
    }
  }
`;

const StyledImage = styled(Img)`
  border-radius: ${styleVariables.borderRadius};
  max-height: 150px;
`;
interface Props {
  pageContext: any;
  data: any;
}
const EventsPage = ({ pageContext, data }: Props) => {
  const {
    allMarkdownRemark: { edges },
  } = data;
  const { dispatch } = useContext(SiteContext);

  useEffect(() => {
    dispatch({ type: 'SET_ACTIVE_PAGE', payload: '' });
  }, []);
  return (
    <Layout hasHero={false}>
      <SEO title="Updates"></SEO>
      <HTag>Events</HTag>
      <StyledUpdates>
        {edges.map((item: any, index: number) => {
          return (
            <StyledArticle key={item.node.id}>
              <Link to={`/${item.node.frontmatter.path}`}>
                <StyledImage
                  fluid={
                    item.node.frontmatter.featuredImage.childImageSharp.fluid
                  }
                />
              </Link>
              <h2>
                <Link to={`/${item.node.frontmatter.path}`}>
                  {item.node.frontmatter.title}
                </Link>
              </h2>
              <time>
                {dayjs().add(index, 'day').add(6, 'M').format('MM/DD/YYYY')}
              </time>
              <p>{item.node.excerpt}</p>
            </StyledArticle>
          );
        })}
      </StyledUpdates>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___date }
      filter: {
        frontmatter: { draft: { eq: false } }
        fields: { sourceInstanceName: { eq: "event" } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date
            path
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default EventsPage;
